// Hooks
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddressCardProps } from 'components/shipping-addresses-v2/address-card/address-card.props';
// Components & UI
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import UpdateCartModalContent, {
    FailureUpdateCartModalContent
} from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
// State
import { getEasyRefillMainCart } from 'state/easy-refill/easy-refill.helpers';
import {
    easyRefillGetCartRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillUpdateShippingRoutine
} from 'state/easy-refill/easy-refill.routines';
import {
    cartSelector,
    easyRefillAddressesSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillIsCaregiverSelector
} from 'state/easy-refill/easy-refill.selectors';

// Styles
import './easy-refill-shipping-address.style.scss';
import ShippingAddresses from './shipping-addresses/shipping-addresses.component';

// Helper function to get the selected address data.
const getSelectedAddress = (addresses: AddressCardProps[]): AddressCardProps | null => {
    if (!Array.isArray(addresses) || !addresses.length) {
        return null;
    }

    return addresses.find((address) => address.isChecked) || { ...addresses[0], isChecked: true };
};

// Main component
const EasyRefillShippingAddress: React.FC = () => {
    // General
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // States
    const [cardAddresses, setCardAddresses] = useState<AddressCardProps[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<AddressCardProps | null>(null);

    // Selectors
    const easyRefillAddresses = useSelector(easyRefillAddressesSelector);
    const cartsObject = useSelector(cartSelector);
    const epostPatientNum = useSelector(easyRefillEpostPatientNumSelector);
    const cartObject = getEasyRefillMainCart(cartsObject, epostPatientNum);
    const isCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);

    useEffect(() => {
        if (cartObject !== undefined && easyRefillAddresses.length == 0)
            dispatch(easyRefillGetPatientAddressesRoutine.trigger());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const cardAddressMapped: AddressCardProps[] = easyRefillAddresses.map((address) => {
            const cart = cartsObject?.find((cart) => cart?.order.orderBillShip.patientBillAddressSeq !== null);
            const orderBillShip = cart?.order.orderBillShip;

            const cartCurrentShippingSeqNum =
                !isCaregiver && orderBillShip?.dependentAddressSeqNum
                    ? orderBillShip?.dependentAddressSeqNum
                    : orderBillShip?.patientShipAddressSeq;

            const isCurrentCartShippingAddress = address.addressSeqNum === cartCurrentShippingSeqNum;

            return {
                addressSeqNum: address.addressSeqNum,
                defaultAddress: address.defaultShip,
                isChecked: isCurrentCartShippingAddress,
                address1: address.address1,
                address2: address.address2,
                city: address.city,
                country: address.country,
                state: address.state,
                zipcode: address.zipcode,
                zipcodeFour: address.zipcodeFour,
                defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
                addressType: address.addressTypeDesc,
                isProfile: false
            };
        });

        setCardAddresses(cardAddressMapped);
        setSelectedAddress(getSelectedAddress(cardAddressMapped));
    }, [easyRefillAddresses, cartObject, t]);

    const hasEasyRefillAddresses = Array.isArray(easyRefillAddresses) && easyRefillAddresses.length > 0;
    if (!hasEasyRefillAddresses) {
        return null;
    }

    const handleShipToAddressClick = (address: AddressCardProps) => {
        const selectedAddress = easyRefillAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        columnSectionRef?.current?.handleSaveChangesButtonClick();
        dispatch(
            easyRefillUpdateShippingRoutine.trigger({
                addressSeqNum: selectedAddress?.addressSeqNum || address.addressSeqNum,
                onSuccess: () => {
                    dispatch(easyRefillGetCartRoutine.trigger());
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCart'
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCart'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="shipping-address__content"
            className="shipping-address"
            editModeContent={
                <ShippingAddresses
                    addressData={cardAddresses}
                    onSetAsShipping={handleShipToAddressClick}
                    isProfile={false}
                    showLabels={true}
                    addNewAddressButtonLabel={t('pages.reviewOrder.addAddress')}
                />
            }
            headerClassName="shipping-address__header"
            title="Shipping Address"
        >
            <address>
                <div className="shipping-address__eyebrow">
                    <p className="shipping-address__type text-left">
                        <small>
                            {t('pages.profile.shippingAddress.addressType', {
                                type: selectedAddress?.addressType?.toLowerCase()
                            })}
                        </small>
                    </p>
                </div>
                <p>
                    {selectedAddress?.address1} {selectedAddress?.address2}
                </p>
                <p>
                    {selectedAddress?.city}, {selectedAddress?.state}{' '}
                    {selectedAddress?.zipcodeFour
                        ? `${selectedAddress?.zipcode}-${selectedAddress?.zipcodeFour}`
                        : selectedAddress?.zipcode}
                </p>
            </address>
        </ColumnSectionEditModeToggle>
    );
};
export default EasyRefillShippingAddress;
