import React from 'react';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import { ButtonVariantProps } from 'ui-kit/button/button.props';

import './empty-state.style.scss';

export interface EmptyStateProps {
    icon: React.ComponentType;
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    buttonLabel?: string;
    onNavigate?: () => void;
    dataGALocation?: string;
    buttonVariant?: typeof ButtonVariantProps;
}

const EmptyState: React.FC<EmptyStateProps> = ({
    icon: Icon,
    title,
    description,
    buttonLabel,
    onNavigate,
    dataGALocation,
    buttonVariant = 'outline-primary'
}) => {
    return (
        <div className="empty-state">
            <Icon />

            <div className="empty-state__content">
                <h4>{title}</h4>

                {typeof description === 'string' ? (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(description, {
                                allowedTags: ['br'] // Include more tags if is needed.
                            })
                        }}
                    />
                ) : (
                    <p>{description}</p>
                )}
            </div>

            {buttonLabel && onNavigate && (
                <div className="empty-state__button">
                    <Button
                        async
                        className="sm-full"
                        label={buttonLabel}
                        variant={buttonVariant}
                        type="button"
                        {...(dataGALocation ? { dataGALocation } : {})}
                        onClick={onNavigate}
                    />
                </div>
            )}
        </div>
    );
};

export default EmptyState;
