import { BIRDI_PLANS, PLAN_COVERAGE_TYPE } from 'enums/plans';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    easyRefillCartItemsSelector,
    easyRefillFamilyDependentsSelector,
    easyRefillPlansSelector,
    easyRefillRxsSelector,
    easyRefillRxsToRefillSelector
} from 'state/easy-refill/easy-refill.selectors';

import { PrescriptionCardProps, RxDetails } from 'types/prescription';

import { prescriptionPayloadToProps } from 'util/payload-to-props';
import { doesPlanAllowsPricing } from 'util/pricing';

import usePrescriptionFlow from 'hooks/usePrescriptionFlow';
import usePrescriptionPrice from 'hooks/usePrescriptionPrice';

export interface PriceHistory {
    planAlias: string;
    zipCode: string;
    drugCode: string;
}

const useEasyRefillPrescriptions = () => {
    const { t } = useTranslation();

    const { familyPlanList, ownerPlan } = usePrescriptionFlow();
    const [activeTab, setActiveTab] = useState<string | null>(null);
    const easyRefillFamilyDependents = useSelector(easyRefillFamilyDependentsSelector);
    const easyRefillCartItems = useSelector(easyRefillCartItemsSelector);
    const easyRefillRxsToRefill = useSelector(easyRefillRxsToRefillSelector); // This selector should be used as the cart simulation.
    const easyRefillRxs = useSelector(easyRefillRxsSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);

    // Get the planAlias of the selected user in the medicine cabinet
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedPlan = useMemo(
        () => (activeTab && familyPlanList[activeTab] ? familyPlanList[activeTab] : ownerPlan) as BIRDI_PLANS,
        [ownerPlan, activeTab, familyPlanList]
    );

    const { showInsurancePrice, pricingZipCode, getPrescriptionPrices, mapPrescriptionsWithPrice } =
        usePrescriptionPrice();

    // Validate if the user's plan should display prices
    const shouldPlanShowPrice: boolean = useMemo(
        () => showInsurancePrice || doesPlanAllowsPricing(selectedPlan),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showInsurancePrice, selectedPlan]
    );

    // Function to update Rx information whenever there are items added to the cart
    const updatePrescriptionsFromCart = useCallback(
        (prescriptions: RxDetails[]): RxDetails[] =>
            prescriptions.map((prescription) => {
                const { rxNumber } = prescription;
                const isInCart = (easyRefillCartItems || []).some((rx) => rx.rxNumber === rxNumber);
                return { ...prescription, inOrderCart: isInCart };
            }),
        [easyRefillCartItems]
    );

    // Get the prescriptions and map them to return them as a single structure of data
    const rawPrescriptions: RxDetails[] = useMemo(() => {
        if (easyRefillFamilyDependents.length && activeTab) {
            const dependent = easyRefillFamilyDependents.find((item) => item.epostPatientNum === activeTab);
            if (!dependent || dependent.rxResults.length === 0) {
                return [];
            }
            return updatePrescriptionsFromCart(dependent.rxResults);
        } else {
            return updatePrescriptionsFromCart(easyRefillRxs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [easyRefillFamilyDependents, activeTab, easyRefillRxs, easyRefillRxsToRefill, easyRefillCartItems]);

    // We process once the prescription card data transforming
    // it to the correspondent object to render prescription cards
    const prescriptionCards: PrescriptionCardProps[] = useMemo(() => {
        if (rawPrescriptions.length <= 0) {
            return [];
        }

        const planAlias = accountPlans.find(
            (plan) => plan.epostPatientNum === activeTab && plan.coverageType === PLAN_COVERAGE_TYPE.PRIMARY
        )?.planAlias;

        // TODO: There is no a way to know if the plan is onDemand at the dependent
        // level being returned in the Easy refill APIs. Hardcoding for now.
        const isOnDemandPlan = false;

        return rawPrescriptions.map((prescription) =>
            prescriptionPayloadToProps(
                prescription,
                t,
                planAlias,
                isOnDemandPlan,
                easyRefillRxsToRefill.includes(prescription.rxNumber)
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawPrescriptions, activeTab]);

    // When all the information has been loaded, validate which Rxs are eligible to
    // get pricing from api
    const priceEligiblePrescriptions: RxDetails[] = useMemo(() => {
        if (!!pricingZipCode && prescriptionCards.length > 0 && shouldPlanShowPrice) {
            return prescriptionCards.filter((rx) => rx.showPrice).map((rx) => rx.fullRxItem);
        } else {
            return [];
        }
    }, [pricingZipCode, prescriptionCards, shouldPlanShowPrice]);

    // // Adding the price to the prescriptions
    const prescriptionCardsWithprice: PrescriptionCardProps[] = useMemo(
        () => mapPrescriptionsWithPrice(prescriptionCards, shouldPlanShowPrice, selectedPlan),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [prescriptionCards, mapPrescriptionsWithPrice]
    );

    // We process once the prescription card data transforming
    // it to the correspondent object to render prescription cards
    const sortedPrescriptions: PrescriptionCardProps[] = useMemo(() => {
        return prescriptionCardsWithprice.sort((a, b) => {
            if (a.inOrderCart !== b.inOrderCart) {
                return a.inOrderCart ? -1 : 1;
            }
            return easyRefillRxsToRefill.includes(a.rxNumber) ? -1 : 1;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescriptionCardsWithprice]);

    // Trigger event to get prices from rx once information has been loaded
    useEffect(() => {
        if (priceEligiblePrescriptions.length > 0) {
            getPrescriptionPrices(priceEligiblePrescriptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceEligiblePrescriptions]);

    return { activeTab, setActiveTab, sortedPrescriptions };
};

export default useEasyRefillPrescriptions;
