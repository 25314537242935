import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import Spinner from 'ui-kit/spinner/spinner';

import EmptyCart from 'components/medicine-cabinet-cart/empty-cart/empty-cart.component';
// UI Kit & Components
import SidebarColumn from 'components/sidebar-column/sidebar-column.component';

import {
    cartSelector,
    easyRefillAccountHasInsuranceSelector,
    easyRefillCartItemsTotalSelector,
    easyRefillcartSubtotalSelector,
    easyRefillOrderBillShipMethodSelector,
    easyRefillOrderPaymentCardSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPlansSelector,
    easyRefillRxsSelector,
    isLoadingCartSelector
} from 'state/easy-refill/easy-refill.selectors';

// Utils
import { EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID } from 'util/cart';

import { EasyRefillCartTotal } from './easy-refill-cart-total/easy-refill-cart-total.component';
import EasyRefillHealthProfile from './easy-refill-health-profile/easy-refill-health-profile.component';
import EasyRefillPaymentMethod from './easy-refill-payment-method/easy-refill-payment-method.component';
import EasyRefillPrescriptionInformation from './easy-refill-prescription-information/easy-refill-prescription-information.component';
import EasyRefillShippingAddress from './easy-refill-shipping-address/easy-refill-shipping-address.component';
import EasyRefillShippingMethod from './easy-refill-shipping-method/easy-refill-shipping-method.component';
import './refill-details-cart.style.scss';

const RefillDetailsCart: React.FC = () => {
    const { t } = useTranslation();

    const cartObject = useSelector(cartSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const shipMethodId = useSelector(easyRefillOrderBillShipMethodSelector);
    const allPaymentData = useSelector(easyRefillPaymentCardsSelector);
    const cartItemsTotal = useSelector(easyRefillCartItemsTotalSelector) || 0;
    const rxResults = useSelector(easyRefillRxsSelector);
    const selectedPaymentMethod = useSelector(easyRefillOrderPaymentCardSelector);
    const accountHasInsurance = useSelector(easyRefillAccountHasInsuranceSelector);
    const estimatedSubtotal = useSelector(easyRefillcartSubtotalSelector);

    const isCartLoading = useSelector(isLoadingCartSelector);

    return (
        <SidebarColumn
            className="refill-details-cart"
            isCartOnly
            cartTotalItems={cartItemsTotal}
            cartLabel={t('pages.easyRefill.prescriptions.responsiveCart.cartButtonLabel')}
        >
            {isCartLoading && cartItemsTotal > 0 && (
                <div className="refill-details-cart__loading__blocker">
                    <Spinner isVisible fullOverlay={false} t={t} color="silver" focusOnShow />
                </div>
            )}

            <div className="refill-details-cart__header">
                <CartIcon color="black" className={'header-icon-container'} />
                <h3>{t('pages.easyRefill.prescriptions.responsiveCart.cartButtonLabel')}</h3>
            </div>

            {cartItemsTotal === 0 && isCartLoading ? (
                <div className="refill-details-cart__loading">
                    <Spinner isVisible fullOverlay={false} t={t} color="silver" />
                </div>
            ) : (
                <>
                    {cartItemsTotal && cartItemsTotal > 0 ? (
                        <>
                            <EasyRefillShippingAddress />
                            <EasyRefillShippingMethod />
                            <EasyRefillPaymentMethod
                                selectedPaymentMethod={selectedPaymentMethod}
                                paymentData={allPaymentData || []}
                                showSelectCardRadioInput
                                showSetDefaultLink
                            />
                            <EasyRefillHealthProfile />
                            <EasyRefillPrescriptionInformation
                                cartObject={cartObject}
                                isInsuranceAccount={accountHasInsurance}
                                prescriptions={rxResults}
                                accountPlans={accountPlans}
                            />
                            <EasyRefillCartTotal
                                cartObject={cartObject}
                                prescriptionsObject={rxResults}
                                isUnknownPrice={false}
                                initialOrderPrice={String(estimatedSubtotal)}
                                currentShippingPrice={
                                    shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0
                                }
                                isInsuranceAccount={accountHasInsurance}
                            />
                        </>
                    ) : (
                        <>
                            {!isCartLoading && (
                                <EmptyCart
                                    title={t('components.medicineCabinetCart.empty.title')}
                                    body={t('components.medicineCabinetCart.empty.body')}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </SidebarColumn>
    );
};

export default RefillDetailsCart;
