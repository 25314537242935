import { RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
// Hooks
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & Components
import ColumnSectionEditModeToggle from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
// Account, Drugs, Profile, Prescriptions & Cart
import { easyRefillGetCartRoutine, easyRefillGetPatientDataRoutine } from 'state/easy-refill/easy-refill.routines';
import { easyRefillCancelOrderLineRoutine } from 'state/easy-refill/easy-refill.routines';
import {
    easyRefillCartItemsSelector,
    easyRefillIsCaregiverSelector,
    easyRefillOrderAddressSelector,
    easyRefillRxsToOrderSelector
} from 'state/easy-refill/easy-refill.selectors';

// Types
import { EasyRefillCartPayload, ExtendedEasyRefillCartObjectPayload, ExtendedEasyRefillRxs } from 'types/easy-refill';
import { PlansObjectPayload } from 'types/plans';
import { RxDetails } from 'types/prescription';

import { EXPEDITED_SHIPPING_COST } from 'util/cart';
import { processEasyRefillCart } from 'util/easy-refill';
import { noop } from 'util/function';
// Utils & helpers
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

import { useEasyRefill } from 'hooks/useEasyRefill';

// Styles
import './easy-refill-prescription-information.style.scss';
import PrescriptionItem, { PrescriptionItemProps } from './prescription-item/prescription-item.component';

export interface EasyRefillPrescriptionInformationProps {
    prescriptions: RxDetails[];
    cartObject?: EasyRefillCartPayload[];
    accountPlans?: PlansObjectPayload[];
    isInsuranceAccount: boolean;
    onPrescriptionRemoved?: (removedCard: ExtendedEasyRefillRxs) => void;
    isToggleDisabled?: boolean;
}

const EasyRefillPrescriptionInformation: React.FC<EasyRefillPrescriptionInformationProps> = (props) => {
    const {
        onPrescriptionRemoved = noop,
        prescriptions,
        isInsuranceAccount,
        isToggleDisabled = true,
        cartObject,
        accountPlans
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedEasyRefillCartObjectPayload[]>([]);

    const easyRefillCartItems = useSelector(easyRefillCartItemsSelector);
    const isCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const easyRefillRxsToOrder = useSelector(easyRefillRxsToOrderSelector);
    const orderAddressSelector = useSelector(easyRefillOrderAddressSelector);

    const { drugDiscountPrices } = useSelector(drugSelector);
    const { showBirdiPricing } = useEasyRefill();

    const handlePrescriptionsError = React.useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleRemovePrescription = React.useCallback(
        (
                prescriptionToRemove: ExtendedEasyRefillRxs,
                cartObject: ExtendedEasyRefillCartObjectPayload
            ): PrescriptionItemProps['onRemovePrescriptionClick'] =>
            () => {
                if (!prescriptionToRemove) {
                    return;
                }

                const epostNumPatient = prescriptionToRemove.prescriptionDetail.epostPatientNum;
                const rxNumber = prescriptionToRemove.rxNumber;
                const lineId = prescriptionToRemove.epostRxScriptId;
                const orderNum = prescriptionToRemove.prescriptionDetail.epostOrderNum;

                dispatch(
                    easyRefillCancelOrderLineRoutine.trigger({
                        rxNumber,
                        lineId,
                        orderNum,
                        epostNumPatient,
                        onSuccess: () => {
                            TrackCheckoutStep({
                                stepName: 'remove',
                                step: '1',
                                carts: [cartObject],
                                prescriptions: prescriptions,
                                t: t,
                                shippingCost: cartObject?.orderHeader?.orderHighPriority
                                    ? `${EXPEDITED_SHIPPING_COST}`
                                    : '0',
                                accountHasInsurance: isInsuranceAccount
                            });
                            onPrescriptionRemoved(prescriptionToRemove);
                            dispatch(easyRefillGetCartRoutine.trigger());
                            dispatch(easyRefillGetPatientDataRoutine.trigger({ withoutLoading: true }));
                        },
                        onFailure: () => {
                            handlePrescriptionsError();
                        }
                    })
                );
            },
        [isInsuranceAccount, dispatch, handlePrescriptionsError, prescriptions, t]
    );

    const getPatientName = (rxNumber: string): string => {
        const cart: EasyRefillCartPayload | undefined = cartObject?.find((cart) =>
            cart?.order?.refillRxs.find((refills) => refills.rxNumber === rxNumber)
        );
        return cart ? `${cart.firstName} ${cart.lastName}` : '';
    };

    useEffect(() => {
        if (showBirdiPricing) {
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: easyRefillRxsToOrder,
                    forceBirdiInsurance: ALLOW_INSURED_BIRDI_PRICE
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, showBirdiPricing]);

    useEffect(() => {
        async function getCartOrder() {
            if (cartObject && accountPlans && easyRefillCartItems) {
                const extendedCart = processEasyRefillCart(
                    cartObject,
                    isInsuranceAccount,
                    easyRefillCartItems,
                    drugDiscountPrices,
                    accountPlans,
                    orderAddressSelector?.zipcode || ''
                );
                setExtendedCartObject(extendedCart);
            }
        }
        getCartOrder();
    }, [easyRefillCartItems, isInsuranceAccount, drugDiscountPrices, accountPlans, cartObject]);

    // Get discount drug prices after the profile and all prescriptions are loaded.
    useEffect(() => {
        if (prescriptions?.length > 0) {
            let birdiPricePrescriptions: RxDetails[] = [];
            // DRX-583 - get prices for Insured users to pre-fetch for cart. We only need to get
            // prices for items in cart.

            if (isInsuranceAccount && ALLOW_INSURED_BIRDI_PRICE) {
                //DRX-996 - Since we have to show a different message when an item is added to the cart,
                //we need to get all Rxs that are in the cart, or could be added to the cart.
                birdiPricePrescriptions = prescriptions.filter((rx) => {
                    return (
                        rx.inOrderCart === true ||
                        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.ELIGIBLE ||
                        rx.webEligibilityStatus === RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ
                    );
                });
            } else {
                //No need to get "pending" prescriptions for Birdi price users
                if (!isInsuranceAccount) {
                    birdiPricePrescriptions = prescriptions.filter(function (rx) {
                        return !!(rx.dispensedProductNumber || rx.writtenProductNumber);
                    });
                }
            }

            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: !!(isInsuranceAccount && ALLOW_INSURED_BIRDI_PRICE),
                    location: 'MedCabinet'
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, prescriptions?.length]);

    return (
        <>
            <ColumnSectionEditModeToggle
                bodyClassName="easy-refill-prescription-information__content"
                className="easy-refill-prescription-information"
                editModeContent={<></>}
                headerClassName="easy-refill-prescription-information__header"
                title="Prescription Information"
                isToggleDisabled={isToggleDisabled}
            >
                <>
                    <div className="cart-review">
                        <div className="cart-review--border-bottom">
                            {extendedCartObject
                                ?.flatMap((item) => item.extendedRefillRxs)
                                .filter((item) => !!item)
                                .map((item: ExtendedEasyRefillRxs) => {
                                    const discountPrice = drugDiscountPrices.find(
                                        (dc) => dc.rxNumber === item.rxNumber
                                    );
                                    const currentCart = extendedCartObject.find((c) =>
                                        c?.extendedRefillRxs?.includes(item)
                                    );

                                    if (currentCart) {
                                        return (
                                            <PrescriptionItem
                                                isLoading={false}
                                                isCaregiver={isCaregiver}
                                                patientName={getPatientName(item.rxNumber)}
                                                key={item.rxNumber}
                                                onRemovePrescriptionClick={handleRemovePrescription(item, currentCart)}
                                                prescription={item}
                                                prescriptionDetails={item.prescriptionDetail as unknown as RxDetails}
                                                discountPrice={discountPrice}
                                                isInsuranceAccount={isInsuranceAccount}
                                            />
                                        );
                                    }

                                    return null;
                                })}
                        </div>
                    </div>
                </>
            </ColumnSectionEditModeToggle>
        </>
    );
};

export default EasyRefillPrescriptionInformation;
